@mixin DefaultTheme {
  --typeface: Inter;

  // SECTION Primary colors
  --primary_color_darker: #830b4b;
  --primary_color_dark: #af0064;
  --primary_color: #db007d;
  --primary_color_light: #fbc4e5;
  --primary_color_lighter: #fbc4e5;
  // !SECTION

  // SECTION Secondary colors
  --secondary_color_darker: #1c4598;
  --secondary_color_dark: #1c4598;
  --secondary_color: #2353AF;
  --secondary_color_light: #1155cc;
  --secondary_color_lighter: #edf6fe;
  // !SECTION

  // SECTION Loader colors
  --loader_outside_circle: #3498db;
  --loader_middle_circle: #e74c3c;
  --loader_inside_circle: #f9c922;
  // !SECTION

  // SECTION Warn colors
  --info_color_lighter: #e9f6fe;
  --info_color_light: #61a2ed;
  --info_color: #2353af;

  --error_color_lighter: #fdeae9;
  --error_color_light: #f8ada7;
  --error_color: #be271d;
  
  --warning_color_lighter: #fffae6;
  --warning_color_light: #efca61;
  --warning_color: #806800;

  --success_color_lighter: #e9f7ee;
  --success_color_light: #7ecc94;
  --success_color: #18662f;
  // !SECTION

  // SECTION Links colors
  --link_primary_color: #1155cc;
  --link_primary_hover_color: #1c4598;
  --link_secondary_color: #63788f;
  --link_secondary_hover_color: #4f657e;
  // !SECTION

  // SECTION Icon colors
  --icon_primary_color: #1155cc;
  --icon_secondary_color: #63788f;
  // !SECTION

  // SECTION Text colors
  --text_color: #394e66;
  --text_color_light: #a3b3c6;

  --heading_primary_color: #172a41;
  --heading_secondary_color: #293b51;

  --small_text_color_1: #4f657e;
  --small_text_color_2: #63788f;

  --button_secondary_text_color: #fff;
  --button_primary_text_color: #fff;
  --button_inactive_color: #b8bdc7;
  // !SECTION

  // SECTION Shadow colors
  --box_shadow_color: #e8eaec;
  // !SECTION

  // SECTION Outline colors
  --outline_color_1: #bfccda;
  --outline_color_2: #dbe2eb;
  --outline_color_3: #949494;
  --outline_color_4: #63788f;
  --outline_color_5: #394e66;
  // !SECTION

  // SECTION Background colors
  --background_color_0: #ffffff;
  --background_color_1: #f0f3f7;
  --background_color_2: #f7f9fa;
  --background_color_3: #b8bdc7;
  --background_color_4: #63788f;
  --background_color_5: #394e66;
  // !SECTION

  // SECTION Skeleton colors
  --skeleton_linear_gradient: #f4f4f4;
  --skeleton_linear_gradient_second: #949494;
  --lr_linear: #394e66;
  --lr_linear_second: #f4f4f4;
  // !SECTION

  // SECTION Social colors
  --facebook_color: #1877f2;
  --google_color: #ea4335;
  --product_review: #152f29;
  // !SECTION

  // SECTION Text colors
  --text_color_gray: #394e66;
  --text_color_gray_light: #717171;
  --text_color_gray_lighter: #cecece;
  --text_color_black: #000000;
  --text_color_white: #ffffff;
  // !SECTION

  // SECTION Scrollbar colors
  --scrollbar_track_color: #f5f5f5;
  --scrollbar_thumb_color: #1155cc;
  // !SECTION

  // SECTION Overlays colors
  --overlay_background_color: rgba(0, 0, 0, 0.4);
  // !SECTION

  // SECTION Custom colors
  --popular_services_background: #f0f3f7;
  --membership_exit_icon_background: rgba(14, 92, 152, 0.05);
  --background_color_light: #ffffff;
  --background_color_dark: #f0f3f7;
  --background_color_slot_row: #e9f6fe;
  --background_secondary_color: #ffffff;
  --background_timeslot_date: #ffffff;
  --background_app_header: #fff;
  --background_app_content: #fff;
  --background_footer: #eef2f8;
  // !SECTION

  // SECTION Sidebar colors
  --background_sidebar: #fff;
  --background_sidebar_header: #edf6fe;
  --background_sidebar_header_text: #394e66;
  // !SECTION

  // * SCROLLBAR COLORS
  --scrollbar_track_color: #f5f5f5;
  --scrollbar_thumb_color: #1155cc;
}
