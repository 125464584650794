@mixin ResetElements {
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
  }

  ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;

    li {
      list-style-type: none;
    }
  }

  a, a:hover {
    text-decoration: none;
  }
}
