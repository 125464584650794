@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Thin.ttf")
    format("truetype-variations");
  font-weight: 200;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Regular.ttf")
    format("truetype-variations");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Medium.ttf")
    format("truetype-variations");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Bold.ttf")
    format("truetype-variations");
  font-weight: 700;
}
