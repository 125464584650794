@import '@angular/cdk/overlay-prebuilt.css';
@import './default-guide-styles';
@import './base/reset_base';

.cdk-overlay-container {
    @import 'node_modules/bootstrap/scss/bootstrap';
}

@mixin ModalsStyles($z_index: 2147483632) {
    .cdk-global-scrollblock {
        overflow-y: auto;
    }
    
    .cdk-overlay-container {
        @include ResetElements;
        @include DefaultGuideStyles;

        // TODO This z-index need to be only 1000 but the shell have header with rely big z-index.
        z-index: $z_index;

        .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
            &.modal-backdrop {
                background: rgba(0, 0, 0, 0.4);
                z-index: 1000;
            }

        }

        .cdk-overlay-pane {
            z-index: 1000;

            &.dialog-modal-panel {
                top: 50%;
                left: 50%;
                max-height: 80%;
                transform: translate(-50%, -50%);
            }

            &.sidebar-modal-panel {
                right: 0;
                height: 100%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            &.page-modal-panel {
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
            }
        }
    }
}
