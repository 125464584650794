/* You can add global styles to this file, and also import other style files */
.container-fluid.content-inner-wrapper.angular-padding-reset {
  // padding: 0;
  display: flex;
  flex-direction: row;
  & > * {
    flex: 1;
    position: relative;
  }
}

@import url('./../../../libs/core/src/fonts/Inter/inter.css');
@import url('./../../../libs/core/src/fonts/Karla/karla.css');
@import url('./../../../libs/core/src/fonts/Open_Sans/opensans.css');
@import url('./../../../libs/core/src/fonts/Raleway/raleway.css');
@import url('./../../../libs/core/src/fonts/Roboto/roboto.css');
@import url('./../../../libs/core/src/fonts/Larken/larken.css');
@import "./../../../libs/core/src/styles/base/reset_base.scss";

@import 'libs/core/src/styles/default-theme';
@import 'libs/core/src/styles/default-guide-styles';
@import 'libs/core/src/styles/modals';

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

:root{
  @include DefaultTheme;
}

serviceos-web-root {
  @import 'node_modules/bootstrap/scss/bootstrap';
  @include ResetElements;
  @include DefaultGuideStyles;
}


@include ModalsStyles();

