@mixin DefaultStyle {
    .pointer {
        cursor: pointer;
    }

    a {
        color: var(--link_primary_color);

        &:hover {
            color: var(--link_primary_hover_color);
        }
    }
}
