@font-face {
    font-family: "OpenSans";
    src: url("./OpenSans-Light.ttf")
      format("truetype-variations");
    font-weight: 200;
  }
@font-face {
  font-family: "OpenSans";
  src: url("./OpenSans-Regular.ttf")
    format("truetype-variations");
  font-weight: 400;
}

@font-face {
  font-family: "OpenSans";
  src: url("./OpenSans-SemiBold.ttf")
    format("truetype-variations");
  font-weight: 500;
}

@font-face {
  font-family: "OpenSans";
  src: url("./OpenSans-Bold.ttf")
    format("truetype-variations");
  font-weight: 700;
}